import * as React from 'react';
import find from 'lodash-es/find';
import Span from 'styleguide/components/Span/Span';
import AppContext from 'contexts/AppContext/AppContext';
import { Address, AddressState, Country } from 'bundles/App/pages/Account/Addresses/types';

interface Props {
  address: Address;
}

const AddressDescription = ({ address }: Props) => {
  const appContext = React.useContext(AppContext);

  const getStateName = (id: number, _state: AddressState) => {
    const state = _state || find(appContext.store.states, elem => elem.id === id);
    return state ? state.name : '';
  };

  const getCountryName = (id: number, _country: Country) => {
    const country = _country || find(appContext.store.countries, elem => elem.id === id);
    return country ? country.iso : '';
  };

  return (
    <div className={appContext.store.admin ? '!pl-0 capitalize' : ''}>
      <Span className="paragraph-mobile block">{address.name}</Span>
      <Span className="paragraph-mobile block">{address.company}</Span>
      <Span className="paragraph-mobile block">{address.address1}</Span>
      <Span className="paragraph-mobile block">{address.address2}</Span>
      <Span className="paragraph-mobile block">{`${address.city}, ${getStateName(
        address.stateId,
        address.state,
      )} 
        ${getCountryName(address.countryId, address.country)} ${address.zipcode}`}</Span>
      <Span className="paragraph-mobile block">{address.phone}</Span>
    </div>
  );
};

export default AddressDescription;
