import apiCall from 'app/libs/utils/api/apiCall';
import { StatesResponse } from './types';
import { SharedErrors } from 'libs/utils/api/types';
import queryString from 'qs';

// states/
export const getStates = (countryId: number) =>
  apiCall.get<StatesResponse, SharedErrors>({
    url: `/states/?${queryString.stringify({ country_id: countryId })}`,
  });
