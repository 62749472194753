export interface AddressState {
  id: number;
  abbr: string;
  name: string;
}

export interface Country {
  id: number;
  iso: string;
  name: string;
  statesRequired: boolean;
}

export interface Address {
  id?: number;
  name: string;
  company: string;
  address1: string;
  address2: string;
  city: string;
  state?: AddressState;
  stateId?: number;
  zipcode: string;
  country?: Country;
  countryId?: number;
  phone: string;
  description?: string;
  verified?: boolean;
  residential?: boolean;
}

export const emptyAddress: Address = {
  id: null,
  name: '',
  company: '',
  address1: '',
  address2: '',
  city: '',
  stateId: null,
  zipcode: '',
  countryId: 214,
  phone: '',
  verified: false,
};

export const emptyCreditCard = {
  name: '',
  number: '',
  month: '',
  year: '',
  verificationValue: '',
};
